'use strict';

angular
    .module('informaApp')
    .component(
        'systemNotificationPopup',
        {
            templateUrl: 'components/system-notification-popup/template.ptl.html',
            controller(SystemNotifications, ConstantsSvc, ModalHelper, $cookies) {
                const vm = this;
                const { RestrictSystemNotification } = ConstantsSvc.COOKIE_NAMES;

                vm.title = null;
                vm.content = null;
                vm.restrictSystemNotification = false;

                vm.onPopupClose = () => {
                    if (vm.restrictSystemNotification) {
                        const cookieExpirationDate = new Date();
                        cookieExpirationDate.setDate(cookieExpirationDate.getDate() + 30);

                        $cookies.put(RestrictSystemNotification, 'true', {
                            expires: cookieExpirationDate
                        });
                    }
                };

                if (!$cookies.get(RestrictSystemNotification)) {
                    SystemNotifications.getExistingSystemNotification().then((data) => {
                        if (!data) {
                            return;
                        }

                        vm.title = data.title;
                        vm.content = data.content;

                        const expirationDate = new Date(data.expirationDate);

                        if (new Date() < expirationDate) {
                            ModalHelper.showModal('.system-notification-popup-modal');
                        }
                    });
                }
            }
        }
    );
